import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FeatureFlags } from '@Models/core/feature-flags';
import { IService } from '@BaseServices/service.base';
import { ServiceFactory } from '../../services/base/service-factory';




@Injectable({
  providedIn: 'root'
})
export class FeatureFlagsService {
  public featureFlagService: IService<FeatureFlags[]>;

  constructor(private http: HttpClient, private serviceFactory: ServiceFactory,) {
    this.featureFlagService = this.serviceFactory.FeatureFlagsService();
  }

  initialize(): Observable<FeatureFlags> {
    this.featureFlagService.FetchAll().then((featureFlags : FeatureFlags[]) => {
      sessionStorage.setItem('featureFlags', JSON.stringify(featureFlags))
    })

    return
  }
}
