<h1 mat-dialog-title>Password Reset Request</h1>
<div mat-dialog-content>
  <form [formGroup]="resetForm">
    <mat-form-field class="w-full my-1">
      <mat-label>Enter Username</mat-label>
      <input type="text" matInput placeholder="Username" formControlName="UserName" />
      <mat-error *ngIf="resetForm.invalid">Username is required</mat-error>
    </mat-form-field>

    <mat-form-field class="w-full my-2">
      <mat-label>Enter Email</mat-label>
      <input type="text" matInput placeholder="johnDoe@example.com" formControlName="Email" />
      <mat-error *ngIf="resetForm.invalid">Email is required</mat-error>
    </mat-form-field>

    <button mat-flat-button color="primary" [disabled]="!this.resetForm.valid" class="btn btn-primary rounded-0 shadow-md" (click)="onSubmit()">Submit</button>
  </form>
</div>
