<mat-list>
  <ng-container *ngFor="let item of notifications">
    <a mat-list-item>
      <mat-icon>notifications</mat-icon>
      <span>
        {{ item.Description }}
      </span>
      <br />
      <span class="test-sm font-bold"> {{ item.NotificationDate | date: 'MM/dd/yyyy' }} 5pm PST.</span>
    </a>
  </ng-container>
</mat-list>
