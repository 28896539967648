<mat-card class="container mx-auto">
  <mat-card-content>
    <h1 class="font-bold text-4xl mb-3">Welcome to HSI's Content Lesson Database!</h1>
    <p>The CLD is an app that tracks and manages the online lessons for HSI.</p>

    <ng-container *ngIf="!this.isLoggedIn && this.showNewCustomerForm">
      <h3 class="font-bold text-xl my-3">Customers</h3>
      <p>View and Download available AICC or SCORM packages.</p>
      <mat-card-actions>
        <button mat-raised-button color="primary" mat-button [routerLink]="'/create-user'">New Customer? Create an Account</button>
      </mat-card-actions>
    </ng-container>

    <h3 class="font-bold text-xl my-3">Content</h3>
    <p>Manage the lessons that are created by HSI. Also, track content created for specific customers.</p>
    <div style="width: 100%">
      <img src="assets/hsi_logo.png" width="400" class="my-6" style="text-align: center" alt="HSI Inc. Company Logo"/>
    </div>
  </mat-card-content>
</mat-card>
