<form class="p2" (submit)="onSubmit($event)">
    
  <mat-form-field class="w-full">
    <mat-label>First Name</mat-label>
    <input matInput value="text" [(ngModel)]="data.first" name="fname-edit-box" placeholder="First Name">
  </mat-form-field>

  <mat-form-field class="w-full">
    <mat-label>Last Name</mat-label>
    <input matInput value="text" [(ngModel)]="data.last" name="lname-edit-box" placeholder="Last Name">
  </mat-form-field>

  <mat-form-field class="w-full" *ngIf="data.Id !== -1">
      <mat-label>Status</mat-label>
      <mat-select [(ngModel)]="data.status" name="status-edit-select">
          <mat-option [value]="" disabled>Select Status</mat-option>
          <mat-option [value]="1">Active</mat-option>
          <mat-option [value]="0">Inactive</mat-option>
      </mat-select>
  </mat-form-field>

  <div class="py-3">
      <button mat-flat-button color="accent" type="submit">Submit</button>
  </div>

  <mat-error *ngIf="error && error != ''" class="mt-2">{{error}}</mat-error>
</form>