import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
@Component({
  selector: 'app-loading-button',
  templateUrl: './loading-button.component.html',
  styleUrls: ['./loading-button.component.css'],
})
export class LoadingButtonComponent implements OnInit {
  @Input() Color: string; //Primary, accent etc..
  @Input() Type: string; //Flat/Raised button types
  @Input() eventObservable: Observable<boolean>; //Observable to listen to boolean events. Passed From Parent
  @Output() onClick = new EventEmitter<any>(); //function call from parent

  public isLoading = false;
  public selectedButton = false;
  public isRaisedButton = false;
  constructor() {}

  ngOnInit(): void {
    this.eventObservable.subscribe((value) => {
      if (value) {
        this.showLoadingIndicator();
      } else {
        this.hideLoadingIndicator();
      }
    });
    if (this.Type !== '') {
      this.isRaisedButton = this.Type.toLowerCase() == 'raised';
    }
  }

  showLoadingIndicator(): void {
    this.isLoading = true;
  }

  hideLoadingIndicator(): void {
    this.isLoading = false;
    this.selectedButton = false;
  }

  onClickHandler(event) {
    this.selectedButton = true;
    this.onClick.emit(event);
  }
}
