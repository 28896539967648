import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Roles } from '@Models/auth/UserRoles';
import { AuthStateService } from '@BaseServices/auth-state.service';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { ServiceFactory } from '@BaseServices/service-factory';
import { SystemNotification } from '@Models/core/system-notification';
import { IService } from '@BaseServices/service.base';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css'],
})
export class NavbarComponent implements OnInit {
  private notificationService: IService<SystemNotification[]>;

  UserRoles = Roles;
  notifications: any = [];
  userNotifications: any = [];

  loggedInUser$: Observable<any>;
  user: any;

  @Output() logout = new EventEmitter<any>();

  constructor(
    private serviceFactory: ServiceFactory,
    public authStateService: AuthStateService,
    public router: Router
  ) {
    this.loggedInUser$ = this.authStateService.loggedInUser$;

    this.loggedInUser$.subscribe(res => {
      this.user = res;
    })

    this.notificationService = this.serviceFactory.SystemNotifications();
  }

  ngOnInit() {
    this.notificationService.FetchAll().then((notifications: SystemNotification[]) => {
      this.FilterNotifications(notifications);
    });
  }

  onLogout() {
    this.logout.emit();
  }

  FilterNotifications(data) {
    if (data) {
      this.notifications = data.filter((x) => x.IsActive == true && this.withinDateRange(x));
    }
  }

  withinDateRange(item: any): any {
    //Only show notifications when alerting users x days of notification date
    const NotificationStartDate = new Date(item.NotificationDate);
    NotificationStartDate.setDate(NotificationStartDate.getDate() - item.AlertDaysBefore);

    if (new Date().getTime() >= NotificationStartDate.getTime() && new Date(item.NotificationDate).getTime() >= new Date().setHours(0, 0, 0, 0)) {
      return true;
    }
    return false;
  }

  isCurrentEditPage(href: string): boolean {
    /*
    Disable other tabs when in edit mode. 
    return boolean if current page is being edited
    */
    const currentEditPage = this.router.url.split('/').pop();
    if (href == currentEditPage) {
      return true;
    }
    return false;
  }

  resetPassword(): void {
    this.router.navigate([`/user/password-reset`]);
  }
}
