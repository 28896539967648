import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-previewer-container',
  templateUrl: './previewer-container.component.html',
  styleUrls: ['./previewer-container.component.css'],
})
export class PreviewerContainerComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
