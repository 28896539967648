import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LessonThumbnailResolver } from '@Resolvers/content-resolvers/lesson-thumbnail.resolver';
import { LessonThumbnailPreviewerComponent } from '@PreviewsComponent/preview-pages/lesson-thumbnail-previewer/lesson-thumbnail-previewer.component';
import { PreviewerContainerComponent } from '@PreviewsComponent/previewer-container/previewer-container.component';
import { ExamKeyComponent } from '@PreviewsComponent/preview-pages/exam-key/exam-key.component';
import { StudyGuideContentComponent } from '@PreviewsComponent/preview-pages/study-guide-content/study-guide-content.component';
import { RoleGuard } from '@Resolvers/role.guard';
import { Roles } from '@Models/auth/UserRoles';

const routes: Routes = [
  {
    path: '',
    component: PreviewerContainerComponent,
    children: [
      {
        path: 'thumbnails/:id',
        component: LessonThumbnailPreviewerComponent,
        runGuardsAndResolvers: 'always',
        resolve: {
          thumbnails: LessonThumbnailResolver,
        },
      },
      {
        path: 'studyguide/:aicclocation/:locale',
        component: StudyGuideContentComponent,
      },
      {
        path: 'examkey/:aicclocation/:locale',
        component: ExamKeyComponent,
        data: { roles: [Roles.SuperAdmin, Roles.MSHA_Instructor] },
        canActivate: [RoleGuard],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PreviewerRoutingModule {}
