import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CourseInformationAPIService, CourseInformationRequest } from '@ApiService/course-information.api.service';
import { AuthStateService } from '@BaseServices/auth-state.service';
import { ServiceFactory } from '@BaseServices/service-factory';
import { IService } from '@BaseServices/service.base';
import { StudyGuide } from '@Models/resources/study-guide';

@Component({
  selector: 'app-study-guide-content',
  templateUrl: './study-guide-content.component.html',
  styleUrls: ['./study-guide-content.component.css', './study-guide-external.css'],
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class StudyGuideContentComponent implements OnInit {
  public JSON: any;
  public CurrentYear = new Date().getUTCFullYear();
  public SyllabusJSON: any;
  public GlossaryJSON: any;
  public ReferencesJSON: any;

  public StudyGuideExists: any = true;
  public ContentLoaded: any = false;
  public AICCLocation: any;
  public AICCLocale: any;

  @ViewChild('SyllabusDiv') SyllabusDiv: ElementRef;
  @ViewChild('TableOfContents') TableOfContents: ElementRef;
  @ViewChild('TableOfContentsList') TableOfContentsList: ElementRef;
  @ViewChild('PageContent') PageContent: ElementRef;
  @ViewChild('Glossary') Glossary: ElementRef;
  @ViewChild('References') References: ElementRef;

  constructor(private router: Router, private route: ActivatedRoute, protected courseInformationService: CourseInformationAPIService, private authStateService: AuthStateService) {
    this.AICCLocation = this.route.snapshot.paramMap.get('aicclocation');
    this.AICCLocale = this.route.snapshot.paramMap.get('locale');

    const request: CourseInformationRequest = {
      AICCPath: this.AICCLocation,
      Token: this.authStateService.getToken(),
      Locale: this.AICCLocale,
    };

    this.courseInformationService.GetCourseInformation(request).subscribe((studyGuideData: any) => {
      if (studyGuideData != undefined) {
        this.JSON = studyGuideData;
        this.SyllabusJSON = this.JSON.resources.find((x) => x.id === 'syllabus');
        this.GlossaryJSON = this.JSON.resources.find((x) => x.id === 'glossary');
        this.ReferencesJSON = this.JSON.resources.find((x) => x.id === 'references');
      } else {
        this.StudyGuideExists = false;
      }
    });
  }

  ngOnInit() {}

  ngAfterContentInit() {
    if (this.SyllabusDiv !== undefined) {
      this.ContentLoaded = true;
    } else {
      setTimeout(() => {
        this.ngAfterContentInit();
      }, 1000);
    }

    if (this.ContentLoaded) {
      this.loadSyllabus();
      this.loadTableOfContents();
      this.loadSections();
      this.loadGlossary();
      this.loadReferences();
    }
  }

  loadSyllabus() {
    //check if exists
    if (this.SyllabusJSON === undefined) {
      // no syllabus node to display
      this.SyllabusDiv.nativeElement.remove();
    } else {
      //check for compatible data model version (1.1)
      const syll = this.SyllabusJSON;
      let output = '<h1>Syllabus</h1>';
      if (syll.data.model.version === '1.1') {
        output += '<p>' + syll.data.content.description + '</p>';
        output += syll.data.content.objectiveStem;
        output += '<ul>';
        syll.data.content.objectives.forEach((element) => {
          output += '<li>' + element + '</li>';
        });
        output += '</ul>';
      }
      //show syllabus
      this.SyllabusDiv.nativeElement.innerHTML = output;
    }
  }
  loadTableOfContents() {
    let tocs = '<ol>';
    for (let i = 0; i < this.JSON.sections.length; i++) {
      tocs += '<li>' + this.JSON.sections[i].title + '</li>';
      let pages = '<ul>';
      for (let x = 0; x < this.JSON.sections[i].pages.length; x++) {
        pages += '<li>' + this.JSON.sections[i].pages[x].title + '</li>';
      }
      if (pages != '<ul>') {
        tocs += pages + '</ul>';
      }
    }

    //Check if we should add Glossary to ToC
    if (this.GlossaryJSON === undefined) {
      //no glossary to add
    } else {
      //add glossary as ToC element
      tocs += '<li>Glossary</li>';
    }

    //Check if we should add References to ToC
    if (this.ReferencesJSON === undefined) {
      //no glossary to add
    } else {
      //add glossary as ToC element
      tocs += '<li>References</li>';
    }

    tocs += '</ol>';
    this.TableOfContentsList.nativeElement.innerHTML = tocs;
  }

  loadSections() {
    let output = '';
    for (let i = 0; i < this.JSON.sections.length; i++) {
      if (this.JSON.sections[i].id !== 'exam') {
        output += "<p>&nbsp;</p><h1 class='section-header'> Section " + (i + 1) + ' - ' + this.JSON.sections[i].title + '</h1>';

        for (let x = 0; x < this.JSON.sections[i].pages.length; x++) {
          output += "<div class='page-detail'>";

          if (this.JSON.sections[i].pages[x].data.id !== 'exam') {
            let fsContent = this.JSON.sections[i].pages[x].data.frameSummary.content;

            // TODO - confirm all framesummary tags are accounted for here

            fsContent = fsContent.replace(/<PageHeader>/g, '<h2>');
            fsContent = fsContent.replace(/<\/PageHeader>/g, '</h2>');

            fsContent = fsContent.replace(/<Header>/g, '<h3>');
            fsContent = fsContent.replace(/<\/Header>/g, '</h3>');

            fsContent = fsContent.replace(/<Text>/g, '<p>');
            fsContent = fsContent.replace(/<\/Text>/g, '</p>');

            // eslint-disable-next-line no-useless-escape
            fsContent = fsContent.replace(/<OrderedList type=\"alpha\">/g, "<ol type='A'>");
            fsContent = fsContent.replace(/<\/OrderedList>/g, '</ol>');

            fsContent = fsContent.replace(/<ListItem>/g, '<li>');
            fsContent = fsContent.replace(/<\/ListItem>/g, '</li>');

            //Replace all [[]] with html tags
            fsContent = fsContent.replaceAll('[[', '<');
            fsContent = fsContent.replaceAll(']]', '>');

            output += '<div>' + fsContent + '</div>';

            output += '</div>';
          }
        }
      }
    }
    this.PageContent.nativeElement.innerHTML = output;
  }

  loadGlossary() {
    if (this.GlossaryJSON === undefined) {
      //no glossary to add
      this.Glossary.nativeElement.remove();
    } else {
      //add glossary content for model 1.0

      const glossary = this.GlossaryJSON;
      if (glossary.data.model.version === '1.0') {
        let output = "<p>&nbsp;</p><h1 class='glossary-header'>Glossary</h1>";

        output += '<dl>';

        glossary.data.content.terms.forEach((element) => {
          output += '<dt>' + element.term + '</dt>';
          output += '<dd>' + element.definition + '</dd>';
        });

        output += '</dl>';

        this.Glossary.nativeElement.innerHTML = output;
      }
    }
  }

  loadReferences() {
    if (this.ReferencesJSON === undefined) {
      //no references to add
      this.References.nativeElement.remove();
    } else {
      //add references content for model 1.1
      const references = this.ReferencesJSON;
      if (references.data.model.version === '1.1') {
        let output = '';
        output += "<p>&nbsp;</p><h1 class='reference-header'> References </h1>";

        output += '<ul>';

        references.data.content.references.forEach((element) => {
          //Check for CFR resources.
          if (element.type == 'cfr') {
            output += '<li>' + this.buildCFRReferenceEntry(element) + '</li>';
          } else {
            output += '<li>' + element.text + '</li>';
          }
        });

        output += '</ul>';

        this.References.nativeElement.innerHTML = output;
      }
    }
  }

  buildCFRReferenceEntry(referenceEntry: any): string {
    let refHtml: string;

    // Fix for slips, replace if we update that lesson.
    if (!referenceEntry.subpart && referenceEntry.subpartNum) {
      referenceEntry.subpart = referenceEntry.subpartNum;
    }

    refHtml = `${referenceEntry.titleNum} CFR ${referenceEntry.partNum}`;

    if (referenceEntry.subpart) {
      refHtml += ` Subpart ${referenceEntry.subpart} &ndash; ${referenceEntry.subpartTitle}`;
    }

    if (referenceEntry.standardNum) {
      refHtml += ` &ndash; ${referenceEntry.partNum}.${referenceEntry.standardNum}` + ` ${referenceEntry.standardTitle}`;
    }
    return refHtml;
  }

  PrintStudyGuide() {
    window.print();
  }
}
