import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthStateService } from '@BaseServices/auth-state.service';
import { LessonSearchStateService } from '@LessonsServices/lesson-search-state.service';
import { Exam, ExamData, ExamRoot } from '@Models/exam/exam';
import { ServiceFactory } from '@BaseServices/service-factory';
import { Location } from '@angular/common';
import { Roles } from '@Models/auth/UserRoles';
import { CourseInformationAPIService, CourseInformationRequest } from '@ApiService/course-information.api.service';

@Component({
  selector: 'app-exam-key',
  templateUrl: './exam-key.component.html',
  styleUrls: ['./exam-key.component.css'],
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class ExamKeyComponent implements OnInit {
  public _examKey: ExamData;
  private _AICCLocation: string;
  private _AICCLocale: string;
  public maxAnswerId = 'G';
  public examManifest = null;
  public transforms: any;
  public userRoleAllowed = false;
  private UserRoles = Roles;
  protected examExists = false;

  @ViewChild('exam-page') ExamPage: ElementRef;

  constructor(
    private authStateService: AuthStateService,

    private courseInformationService: CourseInformationAPIService,
    private route: ActivatedRoute
  ) {
    this.authStateService.loggedInUser$.subscribe((user) => {
      if (this.authStateService.UserPermissionAllowed([this.UserRoles.SuperAdmin, this.UserRoles.MSHA_Instructor])) {
        this.userRoleAllowed = true;
      }
    });

    this._AICCLocation = this.route.snapshot.paramMap.get('aicclocation');
    this._AICCLocale = this.route.snapshot.paramMap.get('locale');
  }

  ngOnInit(): void {
    const request: CourseInformationRequest = {
      AICCPath: this._AICCLocation,
      Token: this.authStateService.getToken(),
      Locale: this._AICCLocale,
    };
    this.courseInformationService.GetCourseInformation(request).subscribe((courseData: any) => {
      if (courseData != undefined) {
        try {
          const examData = courseData.sections.find((element) => {
            return element.isScorable == true;
          });
          this._examKey = examData.pages[0].data.content.examData;
          this.examExists = true;
          this.build();
        } catch (err) {
          console.log('Failed to parse Exam Data: ' + err);
        }
      } else {
        this.examExists = false;
      }
    });
  }

  build(): void {
    const data = this._examKey;

    // Track the question number, because the questions are actualy split
    //  into different lists for each pool.
    let lastQuestion = 0;

    this.transforms = {
      exam: [
        { '<>': 'h1', html: '${title}' },
        { '<>': 'p', class: 'instructions', html: '${instructionText}' },
        {
          '<>': 'div',
          class: 'objectives',
          html: function () {
            return this.transformChild(this, 'objectives');
          },
        },
      ],
      objectives: [
        { '<>': 'h2', text: 'Objective ${objectiveId}' },
        { '<>': 'p', class: 'objective-section', text: 'Associated section: ${section}' },
        {
          '<>': 'div',
          class: 'pools',
          html: function () {
            return this.transformChild(this, 'pools');
          },
        },
      ],
      pools: [
        { '<>': 'h3', text: 'Pool ${poolId}' },
        { '<>': 'p', class: 'instructions', html: '${instructionText}' },
        {
          '<>': 'ol',
          start: function () {
            return '' + (lastQuestion + 1);
          },
          class: 'questions',
          html: function () {
            lastQuestion += this.questions.length;
            return this.transformChild(this, 'questions');
          },
        },
      ],
      questions: {
        '<>': 'li',
        html: [
          { '<>': 'div', class: 'question-text', html: '${text}' },
          {
            '<>': 'img',
            class: function () {
              return 'question-img ' + this.hideIfEmpty(this);
            },
            src: function () {
              return this.getExamImage(this);
            },
          },
          {
            '<>': 'ol',
            class: 'answers correct-${correctAnswer}',
            html: function () {
              return this.getAnswersHtml(this);
            },
          },
          {
            '<>': 'div',
            class: 'feedback-text',
            html: '<h4>Feedback:</h4><p>${feedback}</p>',
          },
        ],
      },
    };
  }
  Print() {
    window.print();
  }
}
