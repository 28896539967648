import { Component, OnInit } from '@angular/core';
import { AuthStateService } from '@BaseServices/auth-state.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css'],
})
export class HomePageComponent implements OnInit {
  showNewCustomerForm = false;
  isLoggedIn: boolean;

  constructor(private authService: AuthStateService) {}

  ngOnInit() {
    this.authService.userIsLoggedIn$.subscribe((isLoggedIn) => {
      this.isLoggedIn = isLoggedIn;
    });
  }
}
