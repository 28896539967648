import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ApiService } from '@ApiService/api.service';
import { LoadingStateService } from '@BaseServices/loading-state.service';
import { MessageStateService } from '@BaseServices/message-state.service';
import { Router } from '@angular/router';
import { InactivityStateService } from '@BaseServices/inactivity-state.service';

import { ApiPath } from '@Models/api/path.enum';
import { Roles } from '@Models/auth/UserRoles';
@Injectable({
  providedIn: 'root',
})
export class AuthStateService implements OnDestroy {
  private loggedInUserSource = new BehaviorSubject<any>(null);
  loggedInUser$ = this.loggedInUserSource.asObservable();

  private userIsLoggedInSource = new BehaviorSubject<boolean>(false);
  userIsLoggedIn$ = this.userIsLoggedInSource.asObservable();

  private InactiveTimerExpiredSub: Subscription;
  private InactiveAlertNotificationSub: Subscription;
  private UserRoles = Roles;

  constructor(
    private apiService: ApiService,
    private router: Router,
    private loadingStateService: LoadingStateService,
    private messageStateService: MessageStateService,
    private inactivityStateService: InactivityStateService
  ) {
    this.InactiveTimerExpiredSub = this.inactivityStateService.timerSub.subscribe((expired) => {
      if (expired) {
        //logout user
        this.messageStateService.setAlertConfirmation('You have been logged out for Inactivity.', 'danger', 'Dismiss', undefined, undefined, this.inactivityStateService._5_Minute_Expiration_Alert);
        this.logout();
      }
    });

    this.InactiveAlertNotificationSub = this.inactivityStateService.alertSub.subscribe((alerted) => {
      if (alerted) {
        this.messageStateService.setAlertConfirmation(
          'You have been Inactive for a while. You will be logged out soon.',
          'danger',
          'Dismiss',
          undefined,
          undefined,
          this.inactivityStateService._5_Minute_Expiration_Alert / 5
        );
      }
    });
  }

  // Actions
  login(formData: any) {
    this.loadingStateService.setStatus(true);

    this.apiService
      .login(ApiPath.AUTH.LOGIN, formData)
      .toPromise()
      .then((res: any) => {
        setTimeout(() => {
          const expDate = res.TokenExpiration;

          localStorage.setItem(
            'CLD_User',
            JSON.stringify({
              token: res.Token,
              userName: res.UserName,
              exp: expDate,
              Admin_Rec_ID: res.Admin_Rec_ID,
              Roles: res.Roles,
              NeedPasswordReset: res.NeedPasswordReset,
              IsInternalUser: res.IsInternalUser
            })
          );

          this.userIsLoggedInSource.next(true);
          this.loggedInUserSource.next({
            token: res.Token,
            userName: res.UserName,
            exp: expDate,
            Roles: res.Roles,
            Admin_Rec_ID: res.Admin_Rec_ID,
            NeedPasswordReset: res.NeedPasswordReset,
            IsInternalUser: res.IsInternalUser
          });

          //Check needs password reset
          if (res.NeedPasswordReset) {
            this.router.navigate(['passwordreset']);
          } else {
            this.router.navigate(['/']);
          }
          //Navigate to home page

          this.loadingStateService.setStatus(false);
          this.messageStateService.setAlert('Login Successful!', 'success');

          //start inactivity timer
          //this.inactivityStateService.Init(new Date(expDate), this);
        }, 500);
      })
      .catch((err) => {
        setTimeout(() => {
          const errMsg = err.statusText === 'Unauthorized' ? 'Invalid Username/Password Combination.' : 'Error Logging In';
          this.messageStateService.setAlert(errMsg, 'danger');
          this.loadingStateService.setStatus(false);
        }, 500);
      });
  }

  logout() {
    //this.inactivityStateService.End();
    localStorage.removeItem('CLD_User');
    this.userIsLoggedInSource.next(false);
    this.loggedInUserSource.next(null);
    this.router.navigate(['/login']);
  }

  sendResetPasswordRequest(formData: any) {
    const resetPasswordRequest = {
      Username: formData.UserName,
      Email: formData.Email,
    };

    this.apiService
      .update(ApiPath.AUTH.PASSWORDRESET, resetPasswordRequest)
      .toPromise()
      .then(() => {
        this.messageStateService.setAlert('Successful sent password reset request', 'success');
      })
      .catch((err) => {
        this.messageStateService.setAlert(err.error.Message, 'danger');
      });
  }

  changePasswordRequest(formData: any) {
    const changePasswordRequest = {
      Admin_Rec_ID: this.getCurrentLoggedInUser().Admin_Rec_ID,
      CurrentPassword: formData.CurrentPassword,
      NewPassword: formData.NewPassword,
      ConfirmPassword: formData.ConfirmPassword,
    };

    this.apiService
      .update(ApiPath.AUTH.CHANGEPASSWORD, changePasswordRequest)
      .toPromise()
      .then(() => {
        this.messageStateService.setAlert('Password changed successful!', 'success');
        this.logout();
      })
      .catch((err) => {
        this.messageStateService.setAlert(err.error.Message, 'danger');
      });
  }

  checkToken() {
    const storedUser: any = JSON.parse(localStorage.getItem('CLD_User'));
    if (storedUser) {
      // Check token exp date
      const stData = storedUser.exp;
      if (new Date(stData) <= new Date()) {
        this.messageStateService.setAlert('Session Expired. Please Sign In Again', 'danger');
        this.logout();
      } else {
        this.loggedInUserSource.next(storedUser);
        this.userIsLoggedInSource.next(true);
      }
    }
  }

  getToken(): string {
    const storedUser: any = JSON.parse(localStorage.getItem('CLD_User'));
    if (storedUser) {
      // Check token exp date
      const stData = storedUser.token;
      return stData;
    }
    return undefined;
  }
  IsLoggedIn() {
    return this.userIsLoggedInSource.getValue() == true;
  }

  getCurrentLoggedInUser() {
    return this.loggedInUserSource.getValue();
  }

  UserPermissionAllowed(RoleTypes) {
    //Return true if current user has role of any of the incoming RoleTypes.
    const currentUser = this.getCurrentLoggedInUser();
    if (this.IsLoggedIn() && currentUser && RoleTypes) {
      try {
        if (RoleTypes.includes(this.UserRoles.All)) {
          return true;
        }
        if (currentUser.Roles.some((role) => RoleTypes.includes(role.RoleID))) {
          return true;
        }
      } catch (err) {
        //if Error checking roles, logout.
        this.logout();
        this.messageStateService.ShowError("Error checking user's permissions. Please Contact Support.");
      }
    }
    return false;
  }

  ngOnDestroy(): void {
    if (!this.InactiveTimerExpiredSub.closed) {
      this.InactiveTimerExpiredSub.unsubscribe();
    }

    if (!this.InactiveAlertNotificationSub.closed) {
      this.InactiveAlertNotificationSub.unsubscribe();
    }
  }
}
