import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PreviewerRoutingModule } from './previewer-routing.module';
import { LessonThumbnailPreviewerComponent } from '../previewer/preview-pages/lesson-thumbnail-previewer/lesson-thumbnail-previewer.component';
import { PreviewerContainerComponent } from './previewer-container/previewer-container.component';
import { SharedModule } from '@Shared/shared.module';
import { MatIconModule } from '@angular/material/icon';
import { ExamKeyComponent } from './preview-pages/exam-key/exam-key.component';
import { StudyGuideContentComponent } from './preview-pages/study-guide-content/study-guide-content.component';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [PreviewerContainerComponent, LessonThumbnailPreviewerComponent, ExamKeyComponent, StudyGuideContentComponent],
  imports: [CommonModule, PreviewerRoutingModule, SharedModule, MatIconModule, MatButtonModule],
  exports: [MatIconModule, MatButtonModule],
})
export class PreviewerModule {}
