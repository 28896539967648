import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { AuthStateService } from '@BaseServices/auth-state.service';

@Injectable({ providedIn: 'root' })
export class AuthCustomerGuard  {
  //, private userService: UserService
  constructor(private router: Router, private authStateService: AuthStateService) { }

  canActivate(route: ActivatedRouteSnapshot) {
    const currentUser = this.authStateService.getCurrentLoggedInUser();

    //Check if User is logged in.
    if (currentUser) {
      if (currentUser.NeedPasswordReset) {
        this.router.navigate(['passwordreset']);
      }
      return true;
    }

    // not logged in so redirect to login page with the return url
    this.router.navigate(['/login']);
    return false;
  }
}
