import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MessageStateService {
  private alertMsgSrc = new BehaviorSubject<{ msg: string; type: string }>({ msg: '', type: 'success' });
  alertMsg$ = this.alertMsgSrc.asObservable();

  private alertConfirmationSrc = new BehaviorSubject<{ msg: string; type: string; button: string; action_class: any; method: string; duration?: number }>({
    msg: '',
    type: 'success',
    button: 'Ok',
    action_class: null,
    method: '',
  });

  alertConfirmation$ = this.alertConfirmationSrc.asObservable();

  constructor() {}

  ShowSuccess(msg: string) {
    const type = 'success';
    this.setAlert(msg, type);
  }

  ShowError(msg: string) {
    const type = 'danger';
    this.setAlert(msg, type);
  }

  setAlert(msg: string, type: string): void {
    this.alertMsgSrc.next({ msg, type });
  }

  setConfirmation(msg: string, type: string, button: string, action_class: any, method: string, duration?: number): void {
    this.alertConfirmationSrc.next({ msg, type, button, action_class, method, duration });
  }

  setAlertConfirmation(msg: string, type: string, button: string, action_class: any, method: string, duration?: number): void {
    this.alertConfirmationSrc.next({ msg, type, button, action_class, method, duration });
  }
}
