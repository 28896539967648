
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { UserInstance } from '@BaseServices/UserInstance';

if (environment.production)
{
  enableProdMode();
}

const Init = () =>
{
  platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));
}

//first load
Init();

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const resetUser = UserInstance.getUserInstance().watchLogout().subscribe(() => Init());