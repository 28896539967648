import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { StatusTextSearch } from '@Models/search/status-text-search';

@Component({
  selector: 'app-status-text-search-form',
  templateUrl: './status-text-search-form.component.html',
  styleUrls: ['./status-text-search-form.component.css'],
})
export class StatusTextSearchFormComponent implements OnInit {
  @Input() filterState: StatusTextSearch = {};
  @Input() canAdd = true;
  @Input() showSearchByStartText = false;
  @Input() searchByStartText: boolean;
  @Input() DisableStatus: boolean = false;
  @Input() DisableTextFilter: boolean = false;

  @Output() filter = new EventEmitter<StatusTextSearch>();
  @Output() add = new EventEmitter<any>();
  @Output() updateSearchByStartText = new EventEmitter<boolean>();

  statusOptions: { value: number; text: string }[] = [
    { value: -1, text: 'All' },
    { value: 1, text: 'Active' },
    { value: 0, text: 'Inactive' },
  ];

  constructor() {}

  ngOnInit() {
    //Default to All Status
    if (this.filterState) {
      this.filterState.status = -1;
    }
    this.filterState = {
      status: -1,
    };
  }

  onSubmit() {
    this.filter.emit(this.filterState);
  }

  onAdd() {
    this.add.emit();
  }

  onUpdateSearchByStartText() {
    this.updateSearchByStartText.emit(this.searchByStartText);
  }
}
