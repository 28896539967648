import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Customer } from '@Models/core/customer';
import { StatusTextSearch } from '@Models/search/status-text-search';
import { ServiceFactory } from '@BaseServices/service-factory';
import { CustomerContact } from '@Models/core/customer-contact';
import { IService } from '@BaseServices/service.base';

@Injectable({
  providedIn: 'root',
})
export class ContactResolver  {
  private customerContactService: IService<CustomerContact[]>;
  private customerService: IService<Customer[]>;
  private customerID: any;
  constructor(private serviceFactory: ServiceFactory) {
    this.customerContactService = this.serviceFactory.CustomerContactService();
    this.customerService = this.serviceFactory.CustomerService();
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<CustomerContact[]> {
    if (parseInt(route.parent.params.customerId)) {
      this.customerID = parseInt(route.parent.params.customerId);
      const search: StatusTextSearch = {
        text: `${this.customerID}`,
      };
      return this.customerContactService.FetchByFilter(search);
    }

    /*
    // Resolve loaded status
    return new Promise((resolve, reject) => {
      this.customerContactService.dataDidLoad$.subscribe((status) => {
        if (status) {
          resolve(true);
        }
      });
    });*/
  }
}
