import { Observable, Subject } from 'rxjs';

export class UserInstance {
  private static instance: UserInstance;
  private _loggedOut: Subject<boolean> = new Subject();
  private loggedOut$ = this._loggedOut.asObservable();

  static getUserInstance() {
    if (!UserInstance.instance) {
      UserInstance.instance = new UserInstance();
    }
    return UserInstance.instance;
  }

  public watchLogout() {
    return this.loggedOut$;
  }

  public restart() {
    this._loggedOut.next(true);
  }
}