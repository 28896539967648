<form [formGroup]="resetForm" (ngSubmit)="onSubmit()">
  <mat-form-field style="width: 100%">
    <mat-label>Current Password</mat-label>
    <input type="password" matInput placeholder="CurrentPassword" formControlName="CurrentPassword" />
  </mat-form-field>

  <mat-form-field style="width: 100%">
    <mat-label>New Password</mat-label>
    <input type="password" matInput placeholder="NewPassword" formControlName="NewPassword" />
    <mat-error *ngIf="resetForm.get('NewPassword').errors?.samePassword">
      Current password cannot match new password.
    </mat-error>
  </mat-form-field>

  <mat-form-field style="width: 100%">
    <mat-label>Confirm Password</mat-label>
    <input type="password" matInput placeholder="ConfirmPassword" formControlName="ConfirmPassword" />
    <mat-error *ngIf="resetForm.get('ConfirmPassword').errors?.mustMatch">
      Passwords do not match.
    </mat-error>
  </mat-form-field>

  <button mat-flat-button color="primary" [disabled]="!this.resetForm.valid" class="btn btn-primary rounded-0 shadow-md">Confirm</button>
</form>
