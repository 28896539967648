<h1 mat-dialog-title>{{ data.object.Id === -1 ? 'Create' : 'Update' }} {{ data.name }}</h1>
<div mat-dialog-content>

    <app-person-form
    [data]="data.object"
    (submitData)="onSubmit()"
    ></app-person-form>

</div>
<button mat-flat-button (click)="this.onNoClick()" class="cancel-modal-button" color="primary">Close</button>
