import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot, ActivatedRoute } from '@angular/router';
import { ServiceFactory } from '@BaseServices/service-factory';
import { IService } from '@BaseServices/service.base';
import { LessonThumbnail } from '@Models/core/lesson-thumbnail';

@Injectable({
  providedIn: 'root',
})
export class LessonThumbnailResolver  {
  private thumbnailService: IService<LessonThumbnail[]>;
  constructor(private serviceFactory: ServiceFactory, private route: ActivatedRoute) {
    this.thumbnailService = this.serviceFactory.LessonThumbnailService();
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<LessonThumbnail[]> {
    if (window.location.pathname.toString().includes('preview/thumbnails')) {
      //Get Thumbnails by ID
      if (route.params.id) {
        return this.thumbnailService.FetchByGuid(route.params.id);
      }
    } else {
      //Get Thumbnails by LessonID
      if (parseInt(route.parent.params.lessonId)) {
        return this.thumbnailService.FetchByID(parseInt(route.parent.params.lessonId));

        // Resolve loaded status
        /*
        return new Promise((resolve, reject) => {
          this.thumbnailService.dataDidLoad$.subscribe((status) => {
            if (status) {
              resolve(status);
            }
          });
        });*/
      }
    }
  }
}
