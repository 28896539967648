import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { ServiceFactory } from '@BaseServices/service-factory';
import { IService } from '@BaseServices/service.base';
import { Transaction } from '@Models/core/customer-transaction';

@Injectable({
  providedIn: 'root',
})
export class TransactionResolver  {
  private transactionService: IService<Transaction[]>;

  constructor(private serviceFactory: ServiceFactory) {
    this.transactionService = this.serviceFactory.TransactionService();
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<Transaction[]> {
    const customerID = this.GetCustomerIDFromURL(state.url);
    if (customerID) {
      return this.transactionService.FetchByID(customerID);
    } else {
      return this.transactionService.FetchAll();
    }
  }

  GetCustomerIDFromURL(urlState): number {
    let customerID = undefined;
    try {
      const url = urlState
        .replace('customers', '')
        .replace('transactions', '')
        .split('/')
        .filter((x) => x != '');

      if (url.length == 1) {
        customerID = parseInt(url[0]);
      }
    } catch (error) {
      console.log(error);
    }
    return customerID;
  }
}
