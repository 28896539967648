import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { AuthStateService } from '@BaseServices/auth-state.service';

@Injectable({ providedIn: 'root' })
export class RoleGuard  {
  //, private userService: UserService
  //1 - Read Only
  //2 - Course
  //3 - Customer
  //4 - Admins
  //-1 - not defined

  constructor(private router: Router, private authStateService: AuthStateService) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const currentUser = this.authStateService.getCurrentLoggedInUser();
    const roleReq: number[] = route.data.roles;
    const userRole: number[] = currentUser.Roles;

    //Check if route requires certain role and if user has one of the needed roles.
    if (roleReq) {
      const userRolesArr = currentUser.Roles.map((role) => role.RoleID);
      if (roleReq.some((role) => userRolesArr.includes(role))) {
        return true;
      } else {
        //TODO: Show Access Denied Prompt
        this.router.navigate(['/']);
        return false;
      }
    }
  }
}
