<form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
  <mat-form-field style="width: 100%">
    <mat-label>Username</mat-label>
    <input type="text" matInput placeholder="Username" formControlName="Admin_ID" />
  </mat-form-field>

  <mat-form-field style="width: 100%">
    <mat-label>Password</mat-label>
    <input type="password" matInput placeholder="Password" formControlName="Password" />
    <!-- add password reset here -->
    <!-- this component will have the validation to see if email is valid -->
  </mat-form-field>

  <button mat-flat-button color="primary" [disabled]="!this.loginForm.valid" class="btn btn-primary rounded-0 shadow-md">Login</button>
  <button mat-button color="primary" (click)="openResetDialog($event)">Forgot Password</button>
</form>
