import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { ServiceFactory } from '@BaseServices/service-factory';
import { IService } from '@BaseServices/service.base';
import { Library } from '@Models/core/library';

@Injectable({
  providedIn: 'root',
})
export class LibraryResolver  {
  private libraryService: IService<Library[]>;

  constructor(private serviceFactory: ServiceFactory) {
    this.libraryService = serviceFactory.LibraryService();
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<Library[]> {
    return this.libraryService.FetchAll();
  }
}
