import { ApiService } from '@ApiService/api.service';
import { IService, ServiceBase } from '@BaseServices/service.base';
import { ApiPath } from '@Models/api/path.enum';
import { Lesson } from '@Models/core/lesson';
import { Locales } from '@Models/core/locales';
import { Injectable } from '@angular/core';
import { Vendor } from '@Models/core/vendor';
import { HSIGroup } from '@Models/core/hsi-group';
import { InstructionalDesigner } from '@Models/core/instructional-designer';
import { TranslatedObjective } from '@Models/core/translated-objective';
import { TranslatedSection } from '@Models/core/translated-section';
import { SupplementalMaterial } from '@Models/core/supplemental-material';
import { SME } from '@Models/core/sme';
import { StudyGuide } from '@Models/resources/study-guide';
import { ProjectManager } from '@Models/core/project-manager';
import { OtherSource } from '@Models/core/other-sources';
import { Library } from '@Models/core/library';
import { SalesLibrary } from '@Models/core/sales-library';
import { SalesSeries } from '@Models/core/sales-series';
import { Configuration } from '@Models/core/configuration';
import { Customer } from '@Models/core/customer';
import { CustomerWrap } from '@Models/core/customer-wrap';
import { SalesRepresentative } from '@Models/core/sales-representative';
import { LMS } from '@Models/core/lms';
import { LessonAffiliation } from '@Models/core/lesson-affiliation';
import { LessonPlatform } from '@Models/core/lesson-platform';
import { LessonScreenShot } from '@Models/core/lesson-screenshot';
import { CustomerContact } from '@Models/core/customer-contact';
import { Transaction } from '@Models/core/customer-transaction';
import { Exam } from '@Models/exam/exam';
import { PublishNote } from '@Models/core/publish-note';
import { LessonObjective } from '@Models/core/lesson-objective';
import { RegulatoryRequirement } from '@Models/core/regulatory-requirement';
import { LessonSection } from '@Models/core/lesson-section';
import { LessonThumbnail, LessonThumbnail_Report } from '@Models/core/lesson-thumbnail';
import { SystemNotification } from '@Models/core/system-notification';
import { UserAdmin } from '@Models/core/user-admins';
import { LessonKeyword } from '@Models/core/lesson-keyword';
import { CustomerDownloadAccount } from '@Models/core/custome-download-account';
import { Programs } from '@Models/core/program';
import { ProgramsWithLessons } from '@Models/core/program-lesson';
import { SalesLibraryAssociation } from '@Models/core/sales-library-association';
import { LessonLocale } from '@Models/core/lesson-locale';
import { AICCClientID } from '@Models/core/aicc_clientId';
import { AICCUtilization } from '@Models/reports/AICC_Utilization';
import { SCORMCourseUtilization, SCORMStudentUtilization } from '@Models/reports/SCORM_Utilizaton';
import { AiccApiService } from '@ApiService/aicc.api.service';
import { ScormApiService } from '@ApiService/scorm.api.service';
import { AICCClient } from '@Models/core/aicc-client';
import { TranslationResponse } from '@LessonsComponent/lessons-forms/lesson-translations-form/lesson-translations-form.component';
import { CatalogLibrary } from '@Models/core/catalog-library';
import { CatalogTopic } from '@Models/core/catalog-topic';
import { AbsorbMetadata } from '@Models/core/absorb-metadata';
import { FeatureFlags } from '@Models/core/feature-flags';
import { CEUTracking } from '@Models/core/ceu_tracking';
import { LessonCEU } from '@Models/core/lesson-ceu';

@Injectable({
  providedIn: 'root',
})
export class ServiceFactory {
  private serviceDict = {};

  constructor(
    private apiService: ApiService,
    private aiccService: AiccApiService,
    private scormService: ScormApiService,
  ) {}

  AbsorbMetadataService(): IService<AbsorbMetadata[]> {
    if (this.serviceDict['AbsorbMetadata']) {
      return this.serviceDict['AbsorbMetadata'];
    } else {
      const service = new ServiceBase<AbsorbMetadata>(this.apiService, ApiPath.LESSON.ABSORBMETADATA);
      this.serviceDict['AbsorbMetadata'] = service;
      return this.serviceDict['AbsorbMetadata'];
    }
  }

  AdminService(): IService<UserAdmin[]> {
    if (this.serviceDict['UserAdmin']) {
      return this.serviceDict['UserAdmin'];
    } else {
      const service = new ServiceBase<UserAdmin>(this.apiService, ApiPath.PROFILES.ADMINS);
      this.serviceDict['UserAdmin'] = service;
      return this.serviceDict['UserAdmin'];
    }
  }
  AdminUserService(): IService<UserAdmin[]> {
    if (this.serviceDict['UserAdmin']) {
      return this.serviceDict['UserAdmin'];
    } else {
      const service = new ServiceBase<UserAdmin>(this.apiService, ApiPath.PROFILES.USERS);
      this.serviceDict['UserAdmin'] = service;
      return this.serviceDict['UserAdmin'];
    }
  }

  AICCCustomerService(): IService<AICCClientID[]> {
    if (this.serviceDict['AICCClientID']) {
      return this.serviceDict['AICCClientID'];
    } else {
      const service = new ServiceBase<AICCClientID>(this.apiService, ApiPath.CUSTOMER.AICCCLIENTID);
      this.serviceDict['AICCClientID'] = service;
      return this.serviceDict['AICCClientID'];
    }
  }

  CatalogLibraryService(): IService<CatalogLibrary[]> {
    if (this.serviceDict['CatalogLibrary']) {
      return this.serviceDict['CatalogLibrary'];
    } else {
      const service = new ServiceBase<CatalogLibrary>(this.apiService, ApiPath.LESSON.CATALOGLIBRARY);
      this.serviceDict['CatalogLibrary'] = service;
      return this.serviceDict['CatalogLibrary'];
    }
  }

  CatalogTopicService(): IService<CatalogTopic[]> {
    if (this.serviceDict['CatalogTopic']) {
      return this.serviceDict['CatalogTopic'];
    } else {
      const service = new ServiceBase<CatalogTopic>(this.apiService, ApiPath.LESSON.CATALOGTOPIC);
      this.serviceDict['CatalogTopic'] = service;
      return this.serviceDict['CatalogTopic'];
    }
  }

  CEUTrackingService(): IService<CEUTracking[]> {
    if (this.serviceDict['CEUTracking']) {
      return this.serviceDict['CEUTracking'];
    } else {
      const service = new ServiceBase<CEUTracking>(this.apiService, ApiPath.LESSON.CEU_TRACKING);
      this.serviceDict['CEUTracking'] = service;
      return this.serviceDict['CEUTracking'];
    }
  }

  ConfigurationService(): IService<Configuration[]> {
    if (this.serviceDict['Configuration']) {
      return this.serviceDict['Configuration'];
    } else {
      const service = new ServiceBase<Configuration>(this.apiService, ApiPath.SYSTEM.CONFIGURATION);
      this.serviceDict['Configuration'] = service;
      return this.serviceDict['Configuration'];
    }
  }

  CustomerDownloadAccountService(): IService<CustomerDownloadAccount[]> {
    if (this.serviceDict['CustomerDownloadAccount']) {
      return this.serviceDict['CustomerDownloadAccount'];
    } else {
      const service = new ServiceBase<CustomerDownloadAccount>(this.apiService, ApiPath.CUSTOMER.CUSTOMERDOWNLOADACCOUNT);
      this.serviceDict['CustomerDownloadAccount'] = service;
      return this.serviceDict['CustomerDownloadAccount'];
    }
  }

  CustomerService(): IService<Customer[]> {
    if (this.serviceDict['Customer']) {
      return this.serviceDict['Customer'];
    } else {
      const service = new ServiceBase<Customer>(this.apiService, ApiPath.CUSTOMER.CUSTOMERS);
      this.serviceDict['Customer'] = service;
      return this.serviceDict['Customer'];
    }
  }

  CustomerSearchService(): IService<Customer[]> {
    if (this.serviceDict['CustomerSearch']) {
      return this.serviceDict['CustomerSearch'];
    } else {
      const service = new ServiceBase<Customer>(this.apiService, ApiPath.CUSTOMER.CUSTOMERSEARCH);
      this.serviceDict['CustomerSearch'] = service;
      return this.serviceDict['CustomerSearch'];
    }
  }

  CustomerContactService(): IService<CustomerContact[]> {
    if (this.serviceDict['CustomerContact']) {
      return this.serviceDict['CustomerContact'];
    } else {
      const service = new ServiceBase<CustomerContact>(this.apiService, ApiPath.CUSTOMER.CUSTOMERCONTACT);
      this.serviceDict['CustomerContact'] = service;
      return this.serviceDict['CustomerContact'];
    }
  }

  FeatureFlagsService(): IService<FeatureFlags[]> {
    if (this.serviceDict['FeatureFlags']) {
      return this.serviceDict['FeatureFlags'];
    } else {
      const service = new ServiceBase<FeatureFlags>(this.apiService, ApiPath.SYSTEM.FEATUREFLAGS);
      this.serviceDict['FeatureFlags'] = service;
      return this.serviceDict['FeatureFlags'];
    }
  }

  TransactionService(): IService<Transaction[]> {
    if (this.serviceDict['CustomerTransaction']) {
      return this.serviceDict['CustomerTransaction'];
    } else {
      const service = new ServiceBase<Transaction>(this.apiService, ApiPath.CUSTOMER.TRANSACTION);
      this.serviceDict['CustomerTransaction'] = service;
      return this.serviceDict['CustomerTransaction'];
    }
  }

  TransactionSearchService(): IService<Transaction[]> {
    if (this.serviceDict['CustomerTransaction']) {
      return this.serviceDict['CustomerTransaction'];
    } else {
      const service = new ServiceBase<Transaction>(this.apiService, ApiPath.CUSTOMER.SEARCH_TRANSACTION);
      this.serviceDict['CustomerTransaction'] = service;
      return this.serviceDict['CustomerTransaction'];
    }
  }

  ExamService(): IService<Exam> {
    if (this.serviceDict['Exam']) {
      return this.serviceDict['Exam'];
    } else {
      const service = new ServiceBase<Exam>(this.apiService, ApiPath.EXAM.EXAMS);
      this.serviceDict['Exam'] = service;
      return this.serviceDict['Exam'];
    }
  }

  HSIGroupService(): IService<HSIGroup[]> {
    if (this.serviceDict['HSIGroup']) {
      return this.serviceDict['HSIGroup'];
    } else {
      const service = new ServiceBase<HSIGroup>(this.apiService, ApiPath.LESSON.HSIGROUP);
      this.serviceDict['HSIGroup'] = service;
      return this.serviceDict['HSIGroup'];
    }
  }

  InstructionalDesignerService(): IService<InstructionalDesigner[]> {
    if (this.serviceDict['InstructionalDesigner']) {
      return this.serviceDict['InstructionalDesigner'];
    } else {
      const service = new ServiceBase<InstructionalDesigner>(this.apiService, ApiPath.LESSON.INSTRUCTIONALDESIGNER);
      this.serviceDict['InstructionalDesigner'] = service;
      return this.serviceDict['InstructionalDesigner'];
    }
  }

  KeywordService(): IService<LessonKeyword[]> {
    if (this.serviceDict['LessonKeyword']) {
      return this.serviceDict['LessonKeyword'];
    } else {
      const service = new ServiceBase<LessonKeyword>(this.apiService, ApiPath.LESSON.KEYWORDS);
      this.serviceDict['LessonKeyword'] = service;
      return this.serviceDict['LessonKeyword'];
    }
  }

  BulkKeywordService(): IService<LessonKeyword[]> {
    if (this.serviceDict['BulkKeywords']) {
      return this.serviceDict['BulkKeywords'];
    } else {
      const service = new ServiceBase<LessonKeyword>(this.apiService, ApiPath.LESSON.BULKKEYWORDINSERT);
      this.serviceDict['BulkKeywords'] = service;
      return this.serviceDict['BulkKeywords'];
    }
  }

  SalesLibraryService(): IService<SalesLibrary> {
    if (this.serviceDict['SalesLibrary']) {
      return this.serviceDict['SalesLibrary'];
    } else {
      const service = new ServiceBase<SalesLibrary>(this.apiService, ApiPath.RESOURCES.SALESLIBRARY);
      this.serviceDict['SalesLibrary'] = service;
      return this.serviceDict['SalesLibrary'];
    }
  }
  SalesSeriesService(): IService<SalesSeries[]> {
    if (this.serviceDict['SalesSeries']) {
      return this.serviceDict['SalesSeries'];
    } else {
      const service = new ServiceBase<SalesSeries>(this.apiService, ApiPath.RESOURCES.SALESSERIES);
      this.serviceDict['SalesSeries'] = service;
      return this.serviceDict['SalesSeries'];
    }
  }
  SalesLibraryAssociations(): IService<SalesLibraryAssociation[]> {
    if (this.serviceDict['SalesLibraryAssociations']) {
      return this.serviceDict['SalesLibraryAssociations'];
    } else {
      const service = new ServiceBase<SalesLibraryAssociation>(this.apiService, ApiPath.RESOURCES.SALESLIBRARYASSOCIATIONS);
      this.serviceDict['SalesLibraryAssociations'] = service;
      return this.serviceDict['SalesLibraryAssociations'];
    }
  }
  LibraryService(): IService<Library[]> {
    if (this.serviceDict['Library']) {
      return this.serviceDict['Library'];
    } else {
      const service = new ServiceBase<Library>(this.apiService, ApiPath.LESSON.LIBRARYS);
      this.serviceDict['Library'] = service;
      return this.serviceDict['Library'];
    }
  }

  LessonAffiliationTypeService(): IService<LessonAffiliation[]> {
    if (this.serviceDict['LessonAffiliationType']) {
      return this.serviceDict['LessonAffiliationType'];
    } else {
      const service = new ServiceBase<LessonAffiliation>(this.apiService, ApiPath.LESSON.AFFILIATIONS);
      this.serviceDict['LessonAffiliationType'] = service;
      return this.serviceDict['LessonAffiliationType'];
    }
  }

  LessonService(): IService<Lesson[]> {
    if (this.serviceDict['Lesson']) {
      return this.serviceDict['Lesson'];
    } else {
      const service = new ServiceBase<Lesson>(this.apiService, ApiPath.LESSON.LESSONS);
      this.serviceDict['Lesson'] = service;
      return this.serviceDict['Lesson'];
    }
  }

  LMSService(): IService<LMS[]> {
    if (this.serviceDict['LMS']) {
      return this.serviceDict['LMS'];
    } else {
      const service = new ServiceBase<LMS>(this.apiService, ApiPath.LESSON.LMS);
      this.serviceDict['LMS'] = service;
      return this.serviceDict['LMS'];
    }
  }

  LocaleService(): IService<Locales[]> {
    if (this.serviceDict['Locales']) {
      return this.serviceDict['Locales'];
    } else {
      const service = new ServiceBase<Locales>(this.apiService, ApiPath.LESSON.LOCALES);
      this.serviceDict['Locales'] = service;
      return this.serviceDict['Locales'];
    }
  }

  LessonLocaleService(): IService<LessonLocale[]> {
    if (this.serviceDict['LessonLocale']) {
      return this.serviceDict['LessonLocale'];
    } else {
      const service = new ServiceBase<LessonLocale>(this.apiService, ApiPath.LESSON.LESSONLOCALES);
      this.serviceDict['LessonLocale'] = service;
      return this.serviceDict['LessonLocale'];
    }
  }

  LessonPlatformService(): IService<LessonPlatform[]> {
    if (this.serviceDict['LessonPlatformType']) {
      return this.serviceDict['LessonPlatformType'];
    } else {
      const service = new ServiceBase<LessonPlatform>(this.apiService, ApiPath.LESSON.LESSONPLATFORMS);
      this.serviceDict['LessonPlatformType'] = service;
      return this.serviceDict['LessonPlatformType'];
    }
  }

  LessonObjectiveService(): IService<LessonObjective[]> {
    if (this.serviceDict['LessonObjective']) {
      return this.serviceDict['LessonObjective'];
    } else {
      const service = new ServiceBase<LessonObjective>(this.apiService, ApiPath.LESSON.OBJECTIVES);
      this.serviceDict['LessonObjective'] = service;
      return this.serviceDict['LessonObjective'];
    }
  }

  LessonSectionService(): IService<LessonSection[]> {
    if (this.serviceDict['LessonSection']) {
      return this.serviceDict['LessonSection'];
    } else {
      const service = new ServiceBase<LessonSection>(this.apiService, ApiPath.LESSON.SECTIONS);
      this.serviceDict['LessonSection'] = service;
      return this.serviceDict['LessonSection'];
    }
  }

  LessonThumbnailService(): IService<LessonThumbnail[]> {
    if (this.serviceDict['LessonThumbnail']) {
      return this.serviceDict['LessonThumbnail'];
    } else {
      const service = new ServiceBase<LessonThumbnail>(this.apiService, ApiPath.LESSON.LESSON_THUMBNAILS);
      this.serviceDict['LessonThumbnail'] = service;
      return this.serviceDict['LessonThumbnail'];
    }
  }

  LessonThumbnailExportService(): IService<LessonThumbnail[]> {
    if (this.serviceDict['LessonThumbnailExport']) {
      return this.serviceDict['LessonThumbnailExport'];
    } else {
      const service = new ServiceBase<LessonThumbnail>(this.apiService, ApiPath.REPORTS.EXPORT_LESSON_THUMBNAILS);
      this.serviceDict['LessonThumbnailExport'] = service;
      return this.serviceDict['LessonThumbnailExport'];
    }
  }

  LessonThumbnailReportExportService(): IService<LessonThumbnail_Report[]> {
    if (this.serviceDict['LessonThumbnailReport']) {
      return this.serviceDict['LessonThumbnailReport'];
    } else {
      const service = new ServiceBase<LessonThumbnail_Report>(this.apiService, ApiPath.REPORTS.EXPORT_LESSON_THUMBNAILS_REPORT);
      this.serviceDict['LessonThumbnailReport'] = service;
      return this.serviceDict['LessonThumbnailReport'];
    }
  }

  RegulatoryRequirementService(): IService<RegulatoryRequirement[]> {
    if (this.serviceDict['RegulatoryRequirement']) {
      return this.serviceDict['RegulatoryRequirement'];
    } else {
      const service = new ServiceBase<RegulatoryRequirement>(this.apiService, ApiPath.LESSON.REGULATORYREQUIREMENTS);
      this.serviceDict['RegulatoryRequirement'] = service;
      return this.serviceDict['RegulatoryRequirement'];
    }
  }

  ScreenShotService(): IService<LessonScreenShot[]> {
    if (this.serviceDict['ScreenShot']) {
      return this.serviceDict['ScreenShot'];
    } else {
      const service = new ServiceBase<LessonScreenShot>(this.apiService, ApiPath.LESSON.SCREENSHOTS);
      this.serviceDict['ScreenShot'] = service;
      return this.serviceDict['ScreenShot'];
    }
  }

  OtherSourcesService(): IService<OtherSource[]> {
    if (this.serviceDict['OtherSource']) {
      return this.serviceDict['OtherSource'];
    } else {
      const service = new ServiceBase<OtherSource>(this.apiService, ApiPath.LESSON.OTHERSOURCES);
      this.serviceDict['OtherSource'] = service;
      return this.serviceDict['OtherSource'];
    }
  }

  ProjectManagerService(): IService<ProjectManager[]> {
    if (this.serviceDict['ProjectManager']) {
      return this.serviceDict['ProjectManager'];
    } else {
      const service = new ServiceBase<ProjectManager>(this.apiService, ApiPath.LESSON.PROJECTMANAGER);
      this.serviceDict['ProjectManager'] = service;
      return this.serviceDict['ProjectManager'];
    }
  }

  ProgramsService(): IService<Programs[]> {
    if (this.serviceDict['Programs']) {
      return this.serviceDict['Programs'];
    } else {
      const service = new ServiceBase<Programs>(this.apiService, ApiPath.LESSON.PROGRAMS);
      this.serviceDict['Programs'] = service;
      return this.serviceDict['Programs'];
    }
  }

  ProgramLessonsService(): IService<ProgramsWithLessons[]> {
    if (this.serviceDict['ProgramsWithLessons']) {
      return this.serviceDict['ProgramsWithLessons'];
    } else {
      const service = new ServiceBase<ProgramsWithLessons>(this.apiService, ApiPath.LESSON.PROGRAMSWITHLESSONS);
      this.serviceDict['ProgramsWithLessons'] = service;
      return this.serviceDict['ProgramsWithLessons'];
    }
  }

  PublishNotesService(): IService<PublishNote[]> {
    if (this.serviceDict['PublishNote']) {
      return this.serviceDict['PublishNote'];
    } else {
      const service = new ServiceBase<PublishNote>(this.apiService, ApiPath.LESSON.PUBLISHNOTES);
      this.serviceDict['PublishNote'] = service;
      return this.serviceDict['PublishNote'];
    }
  }

  SalesRepresentativeService(): IService<SalesRepresentative[]> {
    if (this.serviceDict['SalesRepresentative']) {
      return this.serviceDict['SalesRepresentative'];
    } else {
      const service = new ServiceBase<SalesRepresentative>(this.apiService, ApiPath.CUSTOMER.SALESREP);
      this.serviceDict['SalesRepresentative'] = service;
      return this.serviceDict['SalesRepresentative'];
    }
  }

  StudyGuideService(): IService<StudyGuide[]> {
    if (this.serviceDict['StudyGuide']) {
      return this.serviceDict['StudyGuide'];
    } else {
      const service = new ServiceBase<StudyGuide>(this.apiService, ApiPath.RESOURCES.STUDYGUIDE);
      this.serviceDict['StudyGuide'] = service;
      return this.serviceDict['StudyGuide'];
    }
  }

  SupplementalMaterialService(): IService<SupplementalMaterial[]> {
    if (this.serviceDict['SupplementalMaterial']) {
      return this.serviceDict['SupplementalMaterial'];
    } else {
      const service = new ServiceBase<SupplementalMaterial>(this.apiService, ApiPath.LESSON.SUPPMATS);
      this.serviceDict['SupplementalMaterial'] = service;
      return this.serviceDict['SupplementalMaterial'];
    }
  }

  SMEService(): IService<SME[]> {
    if (this.serviceDict['SME']) {
      return this.serviceDict['SME'];
    } else {
      const service = new ServiceBase<SME>(this.apiService, ApiPath.LESSON.SUBJECTMATTEREXPERTS);
      this.serviceDict['SME'] = service;
      return this.serviceDict['SME'];
    }
  }

  SystemNotifications(): IService<SystemNotification[]> {
    if (this.serviceDict['SystemNotification']) {
      return this.serviceDict['SystemNotification'];
    } else {
      const service = new ServiceBase<SystemNotification>(this.apiService, ApiPath.SYSTEM.SYSTEMNOTIFICATIONS);
      this.serviceDict['SystemNotification'] = service;
      return this.serviceDict['SystemNotification'];
    }
  }

  LessonTranslationService(): IService<TranslationResponse> {
    if (this.serviceDict['Translation']) {
      return this.serviceDict['Translation'];
    } else {
      const service = new ServiceBase<any>(this.apiService, ApiPath.TRANSLATIONS.TRANSLATE);
      this.serviceDict['Translation'] = service;
      return this.serviceDict['Translation'];
    }
  }

  LessonCEUService(): IService<LessonCEU[]> {
    if (this.serviceDict['LessonCEU']) {
      return this.serviceDict['LessonCEU'];
    } else {
      const service = new ServiceBase<any>(this.apiService, ApiPath.LESSON.CEU_ASSOCIATION);
      this.serviceDict['LessonCEU'] = service;
      return this.serviceDict['LessonCEU'];
    }
  }

  TranslatedObjectiveService(): IService<TranslatedObjective[]> {
    if (this.serviceDict['TranslatedObjective']) {
      return this.serviceDict['TranslatedObjective'];
    } else {
      const service = new ServiceBase<TranslatedObjective>(this.apiService, ApiPath.LESSON.TRANSLATEDOBJECTIVES);
      this.serviceDict['TranslatedObjective'] = service;
      return this.serviceDict['TranslatedObjective'];
    }
  }

  TranslatedSectionService(): IService<TranslatedSection[]> {
    if (this.serviceDict['TranslatedSection']) {
      return this.serviceDict['TranslatedSection'];
    } else {
      const service = new ServiceBase<TranslatedSection>(this.apiService, ApiPath.LESSON.TRANSLATEDSECTIONS);
      this.serviceDict['TranslatedSection'] = service;
      return this.serviceDict['TranslatedSection'];
    }
  }

  VendorService(): IService<Vendor[]> {
    if (this.serviceDict['Vendor']) {
      return this.serviceDict['Vendor'];
    } else {
      const service = new ServiceBase<Vendor>(this.apiService, ApiPath.LESSON.VENDORS);
      this.serviceDict['Vendor'] = service;
      return this.serviceDict['Vendor'];
    }
  }

  WrapsService(): IService<CustomerWrap[]> {
    if (this.serviceDict['Wrap']) {
      return this.serviceDict['Wrap'];
    } else {
      const service = new ServiceBase<CustomerWrap>(this.apiService, ApiPath.CUSTOMER.CUSTOMERWRAPS);
      this.serviceDict['Wrap'] = service;
      return this.serviceDict['Wrap'];
    }
  }

  /*
  AICC Reports
  */
  AICCStudentUtilizationService(): IService<AICCUtilization[]> {
    if (this.serviceDict['AICCUtilization']) {
      return this.serviceDict['AICCUtilization'];
    } else {
      const service = new ServiceBase<AICCUtilization>(this.aiccService, ApiPath.AICC.STUDENT_UTILIZATION);
      this.serviceDict['AICCUtilization'] = service;
      return this.serviceDict['AICCUtilization'];
    }
  }
  AICCClientService(): IService<AICCClient[]> {
    if (this.serviceDict['AICCClient']) {
      return this.serviceDict['AICCClient'];
    } else {
      const service = new ServiceBase<AICCClient>(this.aiccService, ApiPath.AICC.CLIENTS);
      this.serviceDict['AICCClient'] = service;
      return this.serviceDict['AICCClient'];
    }
  }

  /*
  SCORM Reports
  */
  SCORMStudentUtilizationService(): IService<SCORMStudentUtilization[]> {
    if (this.serviceDict['SCORMStudentUtilization']) {
      return this.serviceDict['SCORMStudentUtilization'];
    } else {
      const service = new ServiceBase<SCORMStudentUtilization>(this.scormService, ApiPath.SCORM.STUDENT_UTILIZATION);
      this.serviceDict['SCORMStudentUtilization'] = service;
      return this.serviceDict['SCORMStudentUtilization'];
    }
  }

  SCORMCourseUtilizationService(): IService<SCORMCourseUtilization[]> {
    if (this.serviceDict['SCORMCourseUtilization']) {
      return this.serviceDict['SCORMCourseUtilization'];
    } else {
      const service = new ServiceBase<SCORMCourseUtilization>(this.scormService, ApiPath.SCORM.COURSE_UTILIZATION);
      this.serviceDict['SCORMCourseUtilization'] = service;
      return this.serviceDict['SCORMCourseUtilization'];
    }
  }
}
