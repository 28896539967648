import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

interface BasicForm{
  Id?: number;
  text?: string;
  status?: number;
}

@Component({
  selector: 'app-basic-form',
  templateUrl: './basic-form.component.html',
  styleUrls: ['./basic-form.component.css']
})
export class BasicFormComponent implements OnInit {

  @Input() data: BasicForm;
  @Input() placeholderText: string;

  @Output() submitData = new EventEmitter<object>();
  @Output() cancel = new EventEmitter<object>();

  error: string;

  constructor() { }

  ngOnInit() {}

  onSubmit(e: Event): void
  {
    e.preventDefault();
    if(this.data.text == '' || this.data.text == null){
      this.error = "A value must be provided.";
      return;
    }
    else{
      this.error = '';
    }
    this.submitData.emit(this.data);
  }

  onCancel(){
    this.cancel.emit();
  }

}
