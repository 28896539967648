import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

export interface ChangePassword {
  CurrentPassword: string;
  NewPassword: string;
  ConfirmPassword: string;
}

@Component({
  selector: 'app-password-reset-form',
  templateUrl: './password-reset-form.component.html',
  styleUrls: ['./password-reset-form.component.css'],
})
export class PasswordResetFormComponent {
  @Output() passwordReset = new EventEmitter<ChangePassword>();

  public resetForm: FormGroup = this.formBuilder.group({
    CurrentPassword: new FormControl('', Validators.required),
    NewPassword: new FormControl('', Validators.required),
    ConfirmPassword: new FormControl('', Validators.required),
  }, {
    validator: [this.NewPasswordCheck, this.ComparePassword]
  });;

  constructor(private formBuilder: FormBuilder) {}

  onSubmit() {
    if (this.resetForm.valid) {
      this.passwordReset.emit(this.resetForm.value);
    }
  }

  NewPasswordCheck(formGroup: FormGroup) {
    if (formGroup.controls['CurrentPassword'].value !== '' && formGroup.controls['NewPassword'].value !== '') {
        const currentPasswordControl = formGroup.controls.CurrentPassword;
        const newPasswordControl = formGroup.controls.NewPassword;

        if (currentPasswordControl.value.toLowerCase() === newPasswordControl.value.toLowerCase()) {
          return newPasswordControl.setErrors({ samePassword: true });
        }
    }
    return null;
  }

  // Validation for new/confrim password
  ComparePassword(formGroup: FormGroup) {
    if (formGroup.controls['NewPassword'].value !== '' && formGroup.controls['ConfirmPassword'].value !== '') {
      const newPasswordControl = formGroup.controls.NewPassword;
      const matchingControl = formGroup.controls.ConfirmPassword;

      if (newPasswordControl.value !== matchingControl.value) {
        return matchingControl.setErrors({mustMatch: true});
      }
    }
    return null;
  }
}
