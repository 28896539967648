import { Component, OnInit } from '@angular/core';
import { MessageStateService } from '@BaseServices/message-state.service';
import { Subscription } from 'rxjs';
import { Queue } from '@Models/queue/IQueue';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';

class SnackBarMessage {
  public Message: string;
  public Type: string;

  constructor( message: string,  type: string) {
    this.Message = message;
    this.Type = type;
  }
}

@Component({
  selector: 'app-alert-messages',
  templateUrl: './alert-messges.component.html',
  styleUrls: ['./alert-messges.component.css'],
})
export class AlertMessgesComponent implements OnInit {
  private MsgSub: Subscription;
  private alertType = 'success';
  private message = '';
  private horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  private verticalPosition: MatSnackBarVerticalPosition = 'top';

  private _Queue: Queue<SnackBarMessage> = new Queue<SnackBarMessage>();
  private _isMessageVisible = false;
  constructor(private messageStateService: MessageStateService, private snackBar: MatSnackBar) {}

  ngOnInit() {
    this.MsgSub = this.messageStateService.alertMsg$.subscribe((alert) => {
      if (alert.msg !== '' && alert.msg !== null) {
        
        const msg = new SnackBarMessage(alert.msg, alert.type);
        this._Queue.enqueue(msg);
      }
    });

    //Subscribe to Queue changes
    this._Queue.onQueueChanged$.subscribe((changed:boolean) => {
      if (changed && !this._isMessageVisible) {
        this.processQueue();
      }
    })
  }

  processQueue() {
    //check if there is a message in the queue, if there is, open it. wait if there is a message visible
    if (this._Queue.size() > 0) {
      const msg = this._Queue.dequeue();
      this.message = msg.Message;
      this.alertType = msg.Type;
      this.open();
    }
  }

  open() {
    const config = new MatSnackBarConfig();
    config.verticalPosition = this.verticalPosition;
    config.horizontalPosition = this.horizontalPosition;
    config.duration = 4000;
    config.panelClass = this.getClassType(this.alertType);

    const snackBarRef = this.snackBar.open(this.message, 'X', config);
    this._isMessageVisible = true;
    snackBarRef.afterDismissed().subscribe(() => {
      this._isMessageVisible = false;
      if(this._Queue.size() > 0) {
        this.processQueue();
      }
      //this.messageStateService.setAlert(null, 'success');
    });
  }

  getClassType(type: string): string[] {
    switch (type) {
      case 'success':
        return ['alert-success'];
      case 'danger':
        return ['alert-danger'];
      case 'general':
        return ['alert-general'];
      default:
        return [''];
    }
  }
}
