export interface Model {
  name: string;
  version: string;
  layout: string;
}

export interface Image {
  id: string;
  src: string;
}

export interface Manifest {
  images: Image[];
}

export interface Answers {
  a: string;
  b: string;
  c: string;
  d: string;
  e: string;
  f: string;
}

export interface Question {
  questionId: number;
  text: string;
  answers: Answers;
  correctAnswer: string;
  feedback: string;
  image: string;
}

export interface Pool {
  poolId: number;
  questionsToAsk: number;
  instructionText: string;
  questions: Question[];
}

export interface Objective {
  objectiveId: number;
  section: number;
  pools: Pool[];
}

export interface ExamData {
  title: string;
  instructionText: string;
  objectives: Objective[];
}

export interface Content {
  title: string;
  examData: ExamData;
}

export interface Exam {
  id: string;
  model: Model;
  manifest: Manifest;
  content: Content;
}

export interface ExamRoot {
  exam: ExamData;
}
