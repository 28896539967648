import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthStateService } from './auth-state.service';

@Injectable({
  providedIn: 'root',
})
export class InactivityStateService implements OnDestroy {
  private _timerExpired = new BehaviorSubject<boolean>(false);
  timerSub = this._timerExpired.asObservable();

  private _alertSource = new BehaviorSubject<boolean>(false);
  alertSub = this._alertSource.asObservable();

  private authService: AuthStateService;
  private _tokenExpirationDate: Date;
  private _isActive = false;
  private _userActivityTimeout;
  private _userAlertTimeout;
  private _userActivitySub: Subject<any> = new Subject<any>();
  private _userAlertSub: Subject<any> = new Subject<any>();
  private _10_Minute_Expiration: number = environment.minutes_of_inactivity * 60 * 1000;
  public _5_Minute_Expiration_Alert: number = (environment.minutes_of_inactivity - 5) * 60 * 1000;

  constructor() {
    window.addEventListener('click', () => {
      //Listen for all click events in DOM
      //Reset when click detected
      this.Reset();
    });
  }

  Init(tokenExpirationDate: Date, authService: AuthStateService) {
    //Initiate timeout
    this.authService = authService;
    this._tokenExpirationDate = tokenExpirationDate;

    this._isActive = true;
    this.Start();

    this._userActivitySub.subscribe(() => {
      // log out user when inactive for > 10 mins
      this.End();
      this._timerExpired.next(true);
    });

    this._userAlertSub.subscribe(() => {
      // log out user when inactive for > 5 mins
      this._alertSource.next(true);
    });
  }

  private Start() {
    //Set timeout to check inactivity after 10 mins
    //default to token expiration if window is shorter.
    const now = new Date();

    const exp = this._tokenExpirationDate.getTime() - now.getTime() < this._10_Minute_Expiration ? this._tokenExpirationDate.getTime() - new Date().getTime() : this._10_Minute_Expiration;
    this._userActivityTimeout = setTimeout(() => {
      //Inactivity time limit reached. Log out
      this._userActivitySub.next(null);
    }, exp);

    this._userAlertTimeout = setTimeout(() => {
      //show alert after 5 mins of inactivity
      this._userAlertSub.next(null);
    }, this._5_Minute_Expiration_Alert);
  }

  Reset() {
    //Reset timer and check if token is near expiration.
    if (this.authService && this.authService.IsLoggedIn()) {
      const now = new Date();
      if (this._tokenExpirationDate.getTime() - now.getTime() <= this._5_Minute_Expiration_Alert) {
        //renew token if within window
        //this.authService.renew();
      }
      clearTimeout(this._userActivityTimeout);
      clearTimeout(this._userAlertTimeout);
      this.Start();
    }
  }

  End() {
    this._isActive = false;
    clearTimeout(this._userActivityTimeout);
    clearTimeout(this._userAlertTimeout);
  }

  isTimerActive(): boolean {
    return this._isActive;
  }

  ngOnDestroy(): void {
    if (this._userActivitySub && !this._userActivitySub.closed) {
      this._userActivitySub.unsubscribe();
    }

    if (this._userAlertSub && !this._userAlertSub.closed) {
      this._userAlertSub.unsubscribe();
    }
  }
}
