import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { ServiceFactory } from '@BaseServices/service-factory';
import { IService } from '@BaseServices/service.base';
import { LMS } from '@Models/core/lms';

@Injectable({
  providedIn: 'root',
})
export class LmsResolver  {
  private lmsService: IService<LMS[]>;

  constructor(private serviceFactory: ServiceFactory) {
    this.lmsService = serviceFactory.LMSService();
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<LMS[]> {
    return this.lmsService.FetchAll();
  }
}
