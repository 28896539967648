import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, Event } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthStateService } from '@BaseServices/auth-state.service';
import { LoadingStateService } from '@BaseServices/loading-state.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  loading$: Observable<boolean>;
  loggedInUser$: Observable<any>;

  constructor(public router: Router, private loadingStateService: LoadingStateService, private authStateService: AuthStateService) {
    this.loading$ = this.loadingStateService.loading$;
    this.loggedInUser$ = this.authStateService.loggedInUser$;

    this.router.events.subscribe((routEvent: Event) => {
      if (routEvent instanceof NavigationStart) {
        if (!this.shouldNotLoadingSpinner(routEvent.url)) this.loadingStateService.setStatus(true);
      }

      if (routEvent instanceof NavigationEnd) {
        setTimeout(() => {
          this.loadingStateService.setStatus(false);
        }, 500);
      }
    });
  }

  ngOnInit() {
    this.checkForTokenTimeout();
  }

  checkForTokenTimeout() {
    this.authStateService.checkToken();
    setTimeout(() => {
      this.checkForTokenTimeout();
    }, 1000 * 60 * 60); //check token every hour
  }

  shouldNotLoadingSpinner(url: string) {
    if (url) {
      let valid = url.match(/lessons\/\d+\/\w+/);
      valid = valid || url.match(/customers\/\d+\/(\D+(?<!transactions))$/);
      if (valid && valid.length > 0) {
        return true;
      }
      return false;
    }

    return false;
  }

  onLogout() {
    this.authStateService.logout();
  }
}
