<form class="w-full border border-gray-500 px-5 py-8 pb-12 lg:pb-10 h-auto relative" (submit)="onSubmit()">
  <mat-form-field class="w-full lg:w-64 px-2" *ngIf="!this.DisableStatus">
    <mat-label>Selected Status</mat-label>
    <mat-select matNativeControl [(ngModel)]="filterState.status" name="form-status-field" matTooltip="Select Status" placeholder="Select Status">
      <mat-option [value]="" disabled>Select Status</mat-option>
      <ng-container *ngFor="let status of statusOptions">
        <mat-option [value]="status.value">{{ status.text }}</mat-option>
      </ng-container>
    </mat-select>
  </mat-form-field>

  <ng-container *ngIf="showSearchByStartText">
    <mat-checkbox [(ngModel)]="searchByStartText" (change)="onUpdateSearchByStartText()" name="search-by-start-text" class="w-full lg:w-64 px-4"> Search for items that start with... </mat-checkbox>
  </ng-container>

  <mat-form-field class="w-full lg:w-64 px-2" *ngIf="!this.DisableTextFilter">
    <mat-label>Search Text</mat-label>
    <input matInput type="text" placeholder="Search Text" name="form-text-field" [(ngModel)]="filterState.text" matTooltip="Enter Text To Filter By Name" />
  </mat-form-field>

  <button mat-stroked-button class="w-full lg:w-8 px-2" matTooltip="Click Or Press Enter To Filter By Status And/Or Name">
    <mat-icon>search</mat-icon>
  </button>

  <div class="flex justify-end float-right my-4 lg:my-0" *ngIf="canAdd" matTooltip="Click To Add New Item">
    <a mat-fab color="accent" (click)="onAdd()">
      <mat-icon>add</mat-icon>
    </a>
  </div>
</form>
