<link
  href="https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
  rel="stylesheet"
/>
<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
<ng-container *ngIf="this.JSON != null" id="body">
  <button mat-fab (click)="PrintStudyGuide()" class="floatingFab no-print">Print</button>

  <table class="container">
    <thead>
      <tr>
        <td colspan="2">
          <div style="float: right; color: #fff; margin: 15px 30px 0px 0px; font-size: 18pt" id="page-header">{{ this.JSON.title }} - Study Guide</div>
        </td>
      </tr>
    </thead>

    <tr>
      <td class="content-cell" colspan="2">
        <!-- Table of contents -->
        <section id="syll" #SyllabusDiv></section>

        <!-- Table of contents -->
        <section id="toc" #TableOfContents>
          <h1>Contents</h1>
          <div id="toc-list" #TableOfContentsList>
            <!-- Table of Contents loads here-->
          </div>
        </section>

        <!-- Study Guide Page Content -->
        <section id="page-content" #PageContent></section>

        <section id="glossary-content" #Glossary></section>

        <section id="references" #References></section>
      </td>
    </tr>

    <tfoot>
      <tr>
        <td id="footer-course-version">{{ this.JSON.title }} - {{ this.JSON.version }}</td>
        <td id="footer-copyright">&copy; {{ this.CurrentYear }} <a href="https://hsi.com" target="_blank">HSI</a>. All Rights Reserved</td>
      </tr>
    </tfoot>
  </table>
</ng-container>

<ng-container *ngIf="this.JSON == null && !this.StudyGuideExists">
  <h1 style="text-align: center">No Study Guide Available</h1>
</ng-container>
