import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

interface PersonForm{
  Id?: number;
  first?: string;
  last?: string;
  status?: number;
}

@Component({
  selector: 'app-person-form',
  templateUrl: './person-form.component.html',
  styleUrls: ['./person-form.component.css']
})
export class PersonFormComponent implements OnInit {

  @Input() data: PersonForm;

  @Output() submitData = new EventEmitter<object>();
  

  error: string;

  constructor() { }

  ngOnInit() {
  }

  onSubmit(e: Event): void
  {
    e.preventDefault();
    if(!this.data.first || !this.data.last || this.data.first == '' || this.data.last == ''){
      this.error = "All values must be provided.";
      return;
    }
    else{
      this.error = '';
    }
    this.submitData.emit(this.data);
  }


}
