import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ServiceFactory } from '@BaseServices/service-factory';
import { IService } from '@BaseServices/service.base';
import { LessonThumbnail } from '@Models/core/lesson-thumbnail';

@Component({
  selector: 'app-lesson-thumbnail-previewer',
  templateUrl: './lesson-thumbnail-previewer.component.html',
  styleUrls: ['./lesson-thumbnail-previewer.component.css'],
})
export class LessonThumbnailPreviewerComponent implements OnInit, OnDestroy {
  private thumbnailService: IService<LessonThumbnail[]>;

  thumbnailID: -1;
  thumbnailSource: any;

  constructor(private serviceFactory: ServiceFactory, private activatedRoute: ActivatedRoute, private _sanitizer: DomSanitizer) {
    this.thumbnailService = this.serviceFactory.LessonThumbnailService();
  }

  ngOnInit() {
    this.activatedRoute.data.subscribe((data) => {
      const image = data.thumbnails ? data.thumbnails[0] : undefined;
      if (image != undefined) {
        this.thumbnailSource = this._sanitizer.bypassSecurityTrustResourceUrl(`data:${image.contentType};base64,` + image.ImageData);
      }
    });
  }

  ngOnDestroy() {}
}
