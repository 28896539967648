/* eslint-disable no-useless-catch */
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { MessageStateService } from '@BaseServices/message-state.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CourseInformationAPIService {
  protected site = environment.API_COURSE_INFORMATION;
  protected apiEndPoint = this.site;
  protected _loadingService: any;
  protected _alertService: MessageStateService;

  constructor(protected http: HttpClient) {}

  GetCourseInformation(requestOptions?: CourseInformationRequest): Observable<any> {
    try {
      const payload = new HttpParams().set('AICCPath', requestOptions.AICCPath).set('Token', requestOptions.Token).set('Locale', requestOptions.Locale);
      return this.http.post<any>(this.apiEndPoint, payload);
    } catch (error) {
      console.error(`Error GetCourseInformation Request: ${error.message}`);
    }
  }
}

export interface CourseInformationRequest {
  AICCPath: string;
  Token: string;
  Locale: string;
}
