import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-basic-form-dialogue',
  templateUrl: './basic-form-dialogue.component.html',
  styleUrls: ['./basic-form-dialogue.component.css']
})
export class BasicFormDialogueComponent {

  constructor(
    public dialogRef: MatDialogRef<BasicFormDialogueComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

    onSubmit(){
      this.dialogRef.close(this.data.object);
    }

    onNoClick(): void {
      this.dialogRef.close();
    }

}
