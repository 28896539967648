import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-password-reset-dialog',
  templateUrl: './password-reset-dialog.component.html',
  styleUrls: ['./password-reset-dialog.component.css']
})
export class PasswordResetDialogComponent {
  public resetForm: FormGroup;

  constructor(private formBuilder: FormBuilder, public dialogRef: MatDialogRef<PasswordResetDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any,) {
    this.resetForm = this.formBuilder.group({
      UserName: ['', Validators.required],
      Email: ['', 
        [Validators.required,
        Validators.email,]
        // Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
      ],
    });
  }

  onSubmit() {
    this.dialogRef.close(this.resetForm.value);
  }

}
