<button mat-fab (click)="Print()" class="floatingFab no-print">Print</button>
<h1 *ngIf="!this.examExists" class="text-center">No Exam Key found for this lesson.</h1>
<h1 *ngIf="this.examExists && !this.userRoleAllowed" class="text-center">You do not have the permissions to view this page</h1>
<div id="exam-page" style="background: white !important; --tw-bg-opacity: 0 !important" *ngIf="this._examKey && this.userRoleAllowed">
  <h1 style="text-decoration: underline; padding: 5px">{{ this._examKey.title }}</h1>
  <p class="instructions">{{ this._examKey.instructionText }}</p>
  <!-- Objectives-->
  <div *ngFor="let obj of this._examKey.objectives" class="objectives">
    <h2>Objective {{ obj.objectiveId }}</h2>

    <p class="objective-section">Associated Section: {{ obj.section }}</p>
    <div *ngFor="let pool of obj.pools" class="pools">
      <h3>Pool {{ pool.poolId }}</h3>
      <p class="instructions">
        {{ pool.instructionText }}
      </p>
      <ol class="questions">
        <ng-container *ngFor="let question of pool.questions">
          <li>
            <div class="question-text">
              {{ question.text }}
            </div>
            <img *ngIf="question.image" src="question.image" class="question-img" />
            <ol class="answers correct-{{ question.correctAnswer }}">
              <ng-container *ngFor="let answer of question.answers | keyvalue">
                <li ngClass="{{ answer.key === question.correctAnswer.toLowerCase() ? 'correct-answer' : '' }}">{{ answer.value }}</li>
              </ng-container>
            </ol>
            <div class="feedback-text" *ngIf="question.feedback">
              <h4>Feedback:</h4>
              <p>{{ question.feedback }}</p>
            </div>
          </li>
        </ng-container>
      </ol>
    </div>
  </div>
</div>
