import { Injectable, NgZone } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthStateService } from '@BaseServices/auth-state.service';
import { MessageStateService } from '@BaseServices/message-state.service';
import { UserInstance } from '@BaseServices/UserInstance';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  authService: AuthStateService;
  messageService: MessageStateService;

  constructor(public ngZone: NgZone, authStateService: AuthStateService, messageStateService: MessageStateService) {
    this.authService = authStateService;
    this.messageService = messageStateService;
  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    try {
      const currentUser = JSON.parse(localStorage.getItem('CLD_User'));

      if (currentUser && request.url !== environment.API_COURSE_INFORMATION) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${currentUser.token}`,
          },
        });
      }
      return next.handle(request).pipe(
        tap(
          () => {},
          (err: any) => {
            if (err instanceof HttpErrorResponse) {
              switch (err.status) {
                case 401:
                  this.messageService.setAlert('Session Expired. Please Sign In Again', 'danger');
                  this.authService.logout();
                  this.ngZone.runOutsideAngular(() => UserInstance.getUserInstance().restart());
                  break;
                case 500:
                  this.messageService.setAlert('Communication Error. There was an error communicating with the server. Please contact your Administrator.', 'danger');
                  break;
                case 200:
                  break;
                default:
                  const msg = err.error || err.message;
                  if (msg) {
                    this.messageService.setAlert(msg, 'danger');
                  }
                  break;
              }
            }
          }
        )
      );
    } catch (err) {
      console.warn(err);
    }
  }
}
