/* eslint-disable no-useless-catch */
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class ScormApiService extends ApiService {
  protected site = environment.API_SCORM_URL;
  protected apiEndPoint = this.site;
}
