<div mat-dialog-content>
  <p class="text-center my-4">{{ data }}</p>
</div>
<div mat-dialog-actions>
  <div class="w-full flex justify-between py-2">
    <div>
      <button mat-flat-button color="accent" [mat-dialog-close]="true" cdkFocusInitial>Ok</button>
    </div>
    <div>
      <button mat-button (click)="onNoClick()">Cancel</button>
    </div>
  </div>
</div>