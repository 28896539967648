import { Component, OnInit } from '@angular/core';
import { AuthStateService } from '@BaseServices/auth-state.service';

@Component({
  selector: 'app-password-reset-page',
  templateUrl: './password-reset-page.component.html',
  styleUrls: ['./password-reset-page.component.css']
})
export class PasswordResetPageComponent implements OnInit {

  constructor(private authStateService: AuthStateService) { }

  ngOnInit(): void {
  }

  //TODO: Send this to the api
  onPasswordReset(formData: any) {
    this.authStateService.changePasswordRequest(formData);
  }

}
