import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { ServiceFactory } from '@BaseServices/service-factory';
import { IService } from '@BaseServices/service.base';
import { CustomerWrap } from '@Models/core/customer-wrap';

@Injectable({
  providedIn: 'root',
})
export class WrapResolver  {
  private wrapsService: IService<CustomerWrap[]>;

  constructor(private serviceFactory: ServiceFactory) {
    this.wrapsService = this.serviceFactory.WrapsService();
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<CustomerWrap[]> {
    return this.wrapsService.FetchAll();
  }
}
