import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PasswordResetDialogComponent } from '@LoginComponent/dialogs/password-reset-dialog/password-reset-dialog.component';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.css'],
})
export class LoginFormComponent implements OnInit {
  public loginForm: FormGroup;
  public email: string;

  @Output() login = new EventEmitter<{ Username: string; Password: string }>();
  @Output() passwordReset = new EventEmitter<{ Username: string; Email: string }>();

  constructor(private formBuilder: FormBuilder, public dialog: MatDialog) {
    this.loginForm = this.formBuilder.group({
      Admin_ID: ['', Validators.required],
      Password: ['', Validators.required],
    });
  }

  ngOnInit() {}

  onSubmit() {
    if (this.loginForm.valid) {
      this.login.emit(this.loginForm.value);
    }
  }

  openResetDialog($event) {
    $event.preventDefault();
    const dialogRef = this.dialog.open(PasswordResetDialogComponent, {
      position: { top: '100px' },
      maxWidth: '400px',
      width: '80%',
      data: { email: this.email },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        console.log('The dialog was closed', result);
        this.email = result;
        this.passwordReset.emit(result);
      }
    });
  }
}
